import { useState } from "react";
import "./App.css";
import converter from "xml-js";

const initialState = {
  campo1: "",
  campo2: "",
  campo3: "",
};
function App() {
  const [state, setState] = useState(initialState);

  const onChange = (e) => {
    const { value, name } = e.target;
    const newState = { ...state };
    newState[name] = value;
    setState(newState);
  };

  const generateXML = () => {
    const value = {
      _declaration: { _attributes: { version: "1.0", encoding: "utf-8" } },
      ...state,
    };
    const options = { compact: true, ignoreComment: true, spaces: 4 };
    const obj = converter.js2xml(value, options);

    // download json data
    // create file in browser
    const fileName = "xml-exemplo";
    // const mydata = JSON.stringify(finalReal, null, 2);
    const blob = new Blob([obj], { type: "text/xml" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".xml";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <label htmlFor="campo1">Campo 1:</label>
          <input
            id="campo1"
            value={state.campo1}
            name="campo1"
            onChange={onChange}
          />
        </div>
        <div>
          <label htmlFor="campo2">Campo 2:</label>
          <input
            id="campo2"
            value={state.campo2}
            name="campo2"
            onChange={onChange}
          />
        </div>
        <div>
          <label htmlFor="campo3">Campo 3:</label>
          <input
            id="campo3"
            value={state.campo3}
            name="campo3"
            onChange={onChange}
          />
        </div>
        <div>
          <button onClick={() => setState(initialState)}>Limpar</button>
          <button onClick={generateXML}>Gerar XML</button>
        </div>
      </header>
    </div>
  );
}

export default App;
